import { useAuthenticator } from "@aws-amplify/ui-react";

import { Tooltip } from "@mui/material";
import { updateWorkspaceName } from "api/Workspace";
import { CaretLeft } from "components/Customer";
import { PageEditorContext } from "components/PageEditor";
import { PortfolioContext } from "components/Portfolio";
import WSDeletionDialog from "components/WSDeleter";
import { useContext, useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
// import "./WSFrame.css";

import {
  freezeWorkspace,
  getWorkspace,
  getWorkspacePermission,
} from "api/Workspace";
import {
  NotosansjpBoldCuriousBlue20px,
  NotosansjpMediumCloudBurst12px,
  NotosansjpNormalCloudBurst14px,
} from "../../styledMixins";

const WSFrame = (props) => {
  const {
    customerId,
    children,
    currentStep,
    step1Value,
    step2Value,
    step3Value,
    factorYearRevisionVersion,
    mode,
  } = props;
  const navigate = useNavigate();

  const openWSList = (e) => {
    e.preventDefault();
    navigate("/workspace");
  };

  return (
    <WSFrameDiv>
      <Header className="ws-header">
        <div
          className="linkback_to_ws_list"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: 20,
            marginTop: 24,
            marginLeft: 55,
            fontSize: 14,
          }}
        >
          <CaretLeft />
          <Anchor href="/workspace" onClick={openWSList}>
            WS一覧
          </Anchor>
        </div>
        <StepBar
          customerId={customerId}
          currentStep={currentStep}
          step1Value={step1Value}
          step2Value={step2Value}
          step3Value={step3Value}
          factorYearRevisionVersion={factorYearRevisionVersion}
          mode={mode}
        />
      </Header>
      <Body className="WSBody">{children}</Body>
    </WSFrameDiv>
  );
};

export default WSFrame;

const Anchor = styled.a`
  display: block;
  text-decoration: none;
  color: rgba(35, 151, 206, 1);

  &:hover {
    font-weight: 700;
    color: #192e55;
  }
`;

const ModeSwitcher = (props) => {
  const { mode, writable } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const pageEditorContext = useContext(PageEditorContext);
  const portfolioContext = useContext(PortfolioContext);
  const viewMode = pageEditorContext.viewMode || portfolioContext.viewMode;
  const { user } = useAuthenticator((context) => [context.user]);
  const userId = user.attributes.sub;

  const path = viewMode
    ? "/workspace/:workspaceId/:someResource"
    : "/workspace/:workspaceId/:someResource/:crud";
  const match = matchPath(path, location.pathname);
  const workspaceId = match?.params?.workspaceId;
  const resource = match?.params?.someResource;
  const [frozen, setFrozen] = useState(true);

  useEffect(() => {
    if (!workspaceId) return;
    getWorkspacePermission({ userId, workspaceId }).then((permission) => {
      if (!permission || (!permission?.read && !permission?.write)) {
        navigate("/workspace");
        return;
      }

      getWorkspace(workspaceId)
        .then((ws) => {
          const newFrozen = ws && "freezed" in ws && ws.freezed;
          if (!viewMode && (newFrozen || !permission.write)) {
            navigate(`/workspace/${workspaceId}/${resource}/`);
            return;
          }
          setFrozen(newFrozen);
        })
        .catch((err) => {
          console.log("WSFrame / ModeSwitcher / getWorkspace err", err);
        });
    });
  }, [workspaceId, viewMode]);

  const basePath = `/workspace/${workspaceId}`;

  const switchHandler = (e) => {
    e.preventDefault();
    const portReport = mode === "port" ? "portfolio" : "report";
    const path = `${basePath}/${portReport}/${viewMode ? "edit" : ""}`;
    navigate(path);
  };
  const dummyHandler = (e) => {
    e.preventDefault();
  };
  const viewIcon = (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: viewMode ? 0 : 100,
        width: viewMode ? 42 : 26,
        height: viewMode ? 42 : 26,
      }}
    >
      <Tooltip title={viewMode ? "" : "閲覧モードに切り替える"}>
        <div
          style={{
            width: viewMode ? 42 : 26,
            height: viewMode ? 42 : 26,
          }}
        >
          <ModeIconButton
            viewEdit="view"
            viewMode={viewMode}
            onClick={viewMode ? dummyHandler : switchHandler}
          />
        </div>
      </Tooltip>
    </div>
  );
  const editIcon = (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: viewMode ? 100 : 0,
        width: viewMode ? 26 : 42,
        height: viewMode ? 26 : 42,
      }}
    >
      <Tooltip title={viewMode ? "編集モードに切り替える" : ""}>
        <div
          style={{
            width: viewMode ? 26 : 42,
            height: viewMode ? 26 : 42,
          }}
        >
          <ModeIconButton
            viewEdit="edit"
            viewMode={viewMode}
            onClick={viewMode ? switchHandler : dummyHandler}
          />
        </div>
      </Tooltip>
    </div>
  );

  return (
    <div
      style={{
        flexGrow: 0,
        minWidth: 52,
        width: 52,
        height: 52,
        position: "relative",
        marginRight: 20,
      }}
    >
      {viewIcon}
      {!frozen && editIcon}
    </div>
  );
};

const ModeIconButton = (props) => {
  const { viewEdit, viewMode, onClick } = props;
  const viewLength = viewMode ? 42 : 26;
  const viewSVG = (
    <SVG
      width={viewLength}
      height={viewLength}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={["view", viewMode ? "selected" : "unselected"].join(" ")}
    >
      <path
        d="M20.3334 17.8333C20.3334 18.2754 20.1578 18.6993 19.8452 19.0118C19.5326 19.3244 19.1087 19.5 18.6667 19.5H5.33335C4.89133 19.5 4.4674 19.3244 4.15484 19.0118C3.84228 18.6993 3.66669 18.2754 3.66669 17.8333V6.16667C3.66669 5.72464 3.84228 5.30072 4.15484 4.98816C4.4674 4.67559 4.89133 4.5 5.33335 4.5H9.50002L11.1667 7H18.6667C19.1087 7 19.5326 7.17559 19.8452 7.48816C20.1578 7.80072 20.3334 8.22464 20.3334 8.66667V17.8333Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.78715 15.8075H9.0942L9.67913 13.2659C9.76578 12.8631 9.838 12.4672 9.91021 12.0714H9.9391C10.0041 12.4672 10.0835 12.8631 10.1702 13.2659L10.7695 15.8075H12.0983L13.0876 10.6617H12.0766L11.6505 13.1756C11.5711 13.7103 11.4917 14.2589 11.4122 14.8145H11.3761C11.2606 14.2589 11.1523 13.7034 11.0367 13.1756L10.4229 10.6617H9.50582L8.88478 13.1756C8.76924 13.7103 8.6537 14.2589 8.54538 14.8145H8.5165C8.43706 14.2589 8.35041 13.7172 8.27097 13.1756L7.84492 10.6617H6.76172L7.78715 15.8075Z" />
      <path d="M15.4642 15.9047C16.699 15.9047 17.4284 15.1895 17.4284 14.3492C17.4284 13.6061 17.0023 13.2103 16.3524 12.9534L15.6519 12.6686C15.197 12.495 14.8215 12.3631 14.8215 11.995C14.8215 11.6547 15.1176 11.4534 15.587 11.4534C16.0275 11.4534 16.3813 11.6061 16.7207 11.877L17.2623 11.2311C16.829 10.8075 16.208 10.5714 15.587 10.5714C14.5038 10.5714 13.7383 11.2172 13.7383 12.0575C13.7383 12.8075 14.2799 13.2242 14.8215 13.4395L15.5364 13.7311C16.013 13.9256 16.338 14.0436 16.338 14.4256C16.338 14.7867 16.0419 15.0159 15.4859 15.0159C15.0165 15.0159 14.511 14.7867 14.1282 14.4464L13.5144 15.1547C14.0344 15.6339 14.7493 15.9047 15.4642 15.9047Z" />
    </SVG>
  );

  const editLength = viewMode ? 26 : 42;
  const editSVG = (
    <SVG
      width={editLength}
      height={editLength}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={["edit", viewMode ? "unselected" : "selected"].join(" ")}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48167 1.67315C9.59635 1.62513 9.71943 1.6004 9.84375 1.6004C9.96807 1.6004 10.0912 1.62513 10.2058 1.67315C10.3198 1.72088 10.4233 1.79069 10.5102 1.87854L13.1215 4.48981C13.2093 4.57673 13.2791 4.68017 13.3269 4.79418C13.3749 4.90886 13.3996 5.03194 13.3996 5.15626C13.3996 5.28058 13.3749 5.40366 13.3269 5.51834C13.2791 5.63234 13.2093 5.73577 13.1215 5.82269L5.95646 12.9877C5.86855 13.0756 5.74932 13.125 5.625 13.125H2.8125C2.56386 13.125 2.3254 13.0262 2.14959 12.8504C1.97377 12.6746 1.875 12.4361 1.875 12.1875V9.56905M9.48167 1.67315C9.36765 1.72089 9.2642 1.79071 9.17727 1.87858L9.48167 1.67315ZM2.8125 9.56917V12.1875H5.43084L12.4621 5.15625L12.4596 5.15374L9.84374 2.53791L9.84123 2.54046L2.8125 9.56917Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63729 3.41854C7.82035 3.23549 8.11715 3.23549 8.30021 3.41854L11.5815 6.69979C11.7645 6.88285 11.7645 7.17965 11.5815 7.36271C11.3984 7.54576 11.1016 7.54576 10.9185 7.36271L7.63729 4.08146C7.45424 3.8984 7.45424 3.6016 7.63729 3.41854Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.04159 9.07284C2.22465 8.88978 2.52144 8.88978 2.7045 9.07284L5.81916 12.1875H12.6562C12.9151 12.1875 13.125 12.3974 13.125 12.6562C13.125 12.9151 12.9151 13.125 12.6562 13.125H5.625C5.50068 13.125 5.38145 13.0756 5.29354 12.9877L2.04159 9.73575C1.85853 9.55269 1.85853 9.2559 2.04159 9.07284Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.94083 5.05917C10.1239 5.24223 10.1239 5.53902 9.94083 5.72208L4.31583 11.3471C4.13277 11.5301 3.83598 11.5301 3.65292 11.3471C3.46986 11.164 3.46986 10.8672 3.65292 10.6842L9.27792 5.05917C9.46098 4.87611 9.75777 4.87611 9.94083 5.05917Z"
      />
    </SVG>
  );

  return viewEdit === "view" ? viewSVG : editSVG;
};

const SVG = styled.svg`
  cursor: pointer;

  &.view {
    top: 0;
    left: 0;
  }
  &.edit {
    bottom: 0;
    right: 0;
  }

  &.selected {
    cursor: initial;
  }

  &.view.selected path,
  &.view.unselected:hover path {
    stroke: unset;
    fill: #1fd7ff;
  }
  &.view.unselected:active path {
    stroke: unset;
    fill: #192e55;
  }
  &.view.selected path:first-child,
  &.view.unselected:hover path:first-child {
    stroke: #1fd7ff;
    fill: unset;
  }
  &.view.unselected:active path:first-child {
    stroke: #192e55;
    fill: unset;
  }

  &.view.unselected path {
    stroke: unset;
    fill: #d2f2ff;
  }
  &.view.unselected path:first-child {
    stroke: #d2f2ff;
    fill: unset;
  }

  &.edit.selected path,
  &.edit.unselected:hover path {
    fill: #1fd7ff;
  }
  &.edit.unselected:active path {
    fill: #192e55;
  }
  &.edit.unselected path {
    fill: #d2f2ff;
  }
`;

const SVG2 = styled.svg`
  cursor: pointer;

  &.view {
    top: 0;
    left: 0;
  }
  &.edit {
    bottom: 0;
    right: 0;
  }
  &.unselected {
    display: none;
  }

  &.selected {
    /* cursor: initial; */
  }
  & path {
    cursor: pointer;
  }

  &.view.selected path,
  &.view.unselected:hover path {
    stroke: unset;
    fill: #1fd7ff;
  }
  &.view.unselected:active path {
    stroke: unset;
    fill: #192e55;
  }
  &.view.selected path:first-child,
  &.view.unselected:hover path:first-child {
    stroke: #1fd7ff;
    fill: unset;
  }
  &.view.unselected:active path:first-child {
    stroke: #192e55;
    fill: unset;
  }

  &.view.unselected path {
    stroke: unset;
    fill: #d2f2ff;
  }
  &.view.unselected path:first-child {
    stroke: #d2f2ff;
    fill: unset;
  }

  &.edit.selected path,
  &.edit.unselected:hover path {
    fill: #1fd7ff;
  }
  &.edit.unselected:active path {
    fill: #192e55;
  }
  &.edit.unselected path {
    fill: #d2f2ff;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Body = styled.div`
  flex-grow: 1;

  & .react-tabs__tab-panel {
    display: none;
  }

  & .react-tabs__tab-panel--selected {
    display: block;
  }

  & .graph-tab .react-tabs__tab {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 35px;
    border: unset;
    align-items: center;
    justify-content: center;
    border-radius: unset;
    ${NotosansjpMediumCloudBurst12px} /* margin-bottom: -13px; */
    color: #2397CE;
    position: relative;
    bottom: -1px;
    cursor: pointer;
  }
  & .react-tabs__tab--selected {
    color: #192e55;
    background-color: #ffffff;
  }

  & .react-tabs__tab--selected .bar {
    width: 120px;
    height: 10px;
    display: block;
    /* background-image: url(img/tab-bottom-bar.svg); */
    position: absolute;
    top: 42px;
    z-index: 9999999;
  }
  & .react-tabs__tab bar {
    /* margin-top: 10px; */
  }

  & .react-tabs__tab-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
    border-bottom: 10px solid #ffffff;
  }
`;

const StepBar = (props) => {
  const {
    customerId,
    step1Value,
    step2Value,
    step3Value,
    factorYearRevisionVersion,
    currentStep = 4,
    mode,
  } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const onClick = (e) => {
    e.preventDefault();
    setShow(true);
  };
  const onClickCloser = (e) => {
    e.preventDefault();
    setShow(false);
  };

  const pageEditorContext = useContext(PageEditorContext);
  const portfolioContext = useContext(PortfolioContext);
  const viewMode = pageEditorContext.viewMode || portfolioContext.viewMode;
  const location = useLocation();
  const path = viewMode
    ? "/workspace/:workspaceId/:someResource"
    : "/workspace/:workspaceId/:someResource/:crud";
  const match = matchPath(path, location.pathname);
  const workspaceId = match?.params?.workspaceId;
  const resourceType = match?.params?.someResource;
  const switchAndClonable = resourceType !== "properties";

  const [workspaceToDelete, setWorkspaceToDelete] = useState(null);

  const createCloneHandler = (e) => {
    e.preventDefault();
    const path = `/workspace/${workspaceId}/properties/edit`;
    window.open(path, "_blank");
  };

  const initiateDeletion = (e) => {
    e.preventDefault();
    setWorkspaceToDelete({ id: workspaceId, name: step2Value });
  };

  const onCancel = (e) => {
    e.preventDefault();
    setWorkspaceToDelete(null);
  };

  const yearRevisionVersion = factorYearRevisionVersion
    ? `中期金融変数年度/版: ${factorYearRevisionVersion.year}年度${factorYearRevisionVersion.revision}版`
    : "";

  const onStep1Click = (e) => {
    e.preventDefault();
    navigate(`/customer/${customerId}/am`);
  };

  const [workspaceName, setWorkspaceName] = useState(step2Value);

  const clickEditName = (e) => {
    e.preventDefault();
    const newWorkspaceName = prompt("新しいWS名を入力してください", step2Value);
    if (newWorkspaceName) {
      updateWorkspaceName({ workspaceId, newWorkspaceName })
        .then(({ data, errors }) => {
          console.log("updateWorkspaceName done", data, errors);
          if (errors) {
            console.log("updateWorkspaceName error", errors);
            alert("WS名の変更に失敗しました。");
          } else {
            setWorkspaceName(newWorkspaceName);
            // リロードする
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log("updateWorkspaceName err", err);
        });
    }
  };

  const editLength = 18;
  const editSVG = (
    <SVG2
      width={editLength}
      height={editLength}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickEditName}
      className={["edit", viewMode ? "unselected" : "selected"].join(" ")}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48167 1.67315C9.59635 1.62513 9.71943 1.6004 9.84375 1.6004C9.96807 1.6004 10.0912 1.62513 10.2058 1.67315C10.3198 1.72088 10.4233 1.79069 10.5102 1.87854L13.1215 4.48981C13.2093 4.57673 13.2791 4.68017 13.3269 4.79418C13.3749 4.90886 13.3996 5.03194 13.3996 5.15626C13.3996 5.28058 13.3749 5.40366 13.3269 5.51834C13.2791 5.63234 13.2093 5.73577 13.1215 5.82269L5.95646 12.9877C5.86855 13.0756 5.74932 13.125 5.625 13.125H2.8125C2.56386 13.125 2.3254 13.0262 2.14959 12.8504C1.97377 12.6746 1.875 12.4361 1.875 12.1875V9.56905M9.48167 1.67315C9.36765 1.72089 9.2642 1.79071 9.17727 1.87858L9.48167 1.67315ZM2.8125 9.56917V12.1875H5.43084L12.4621 5.15625L12.4596 5.15374L9.84374 2.53791L9.84123 2.54046L2.8125 9.56917Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63729 3.41854C7.82035 3.23549 8.11715 3.23549 8.30021 3.41854L11.5815 6.69979C11.7645 6.88285 11.7645 7.17965 11.5815 7.36271C11.3984 7.54576 11.1016 7.54576 10.9185 7.36271L7.63729 4.08146C7.45424 3.8984 7.45424 3.6016 7.63729 3.41854Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.04159 9.07284C2.22465 8.88978 2.52144 8.88978 2.7045 9.07284L5.81916 12.1875H12.6562C12.9151 12.1875 13.125 12.3974 13.125 12.6562C13.125 12.9151 12.9151 13.125 12.6562 13.125H5.625C5.50068 13.125 5.38145 13.0756 5.29354 12.9877L2.04159 9.73575C1.85853 9.55269 1.85853 9.2559 2.04159 9.07284Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.94083 5.05917C10.1239 5.24223 10.1239 5.53902 9.94083 5.72208L4.31583 11.3471C4.13277 11.5301 3.83598 11.5301 3.65292 11.3471C3.46986 11.164 3.46986 10.8672 3.65292 10.6842L9.27792 5.05917C9.46098 4.87611 9.75777 4.87611 9.94083 5.05917Z"
      />
    </SVG2>
  );

  return (
    <StepBarFrame>
      {switchAndClonable && <ModeSwitcher mode={mode} />}
      <StepOne
        number={1}
        label="顧客選択"
        currentStep={currentStep}
        value={step1Value}
        onClick={onStep1Click}
      />
      <div>{editSVG}</div>
      <Step
        number={2}
        label="WS名称設定"
        currentStep={currentStep}
        value={workspaceName || step2Value}
        option={yearRevisionVersion}
        editable={true}
      />
      <Step
        number={3}
        label="政策AM選択"
        currentStep={currentStep}
        value={step3Value}
      />
      <StepFour currentStep={currentStep} mode={mode} />
      <StepFive currentStep={currentStep} onClick={onClick} />
      <Cover show={show} onClick={onClickCloser} />
      <WSDeletionDialog workspace={workspaceToDelete} onCancel={onCancel} />
      {switchAndClonable && (
        <>
          <div style={{ minWidth: 40, flexGrow: 0 }}>
            <Tooltip title="複製を作成する">
              <CloneButton onClick={createCloneHandler}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: "url(/img/stack_blue.svg)",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </CloneButton>
            </Tooltip>
          </div>
          {!viewMode && (
            <div style={{ minWidth: 40, flexGrow: 0, marginLeft: 24 }}>
              <Tooltip title="このWorkspaceを削除する">
                <CloneButton onClick={initiateDeletion}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundImage: "url(/img/Trash.svg)",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </CloneButton>
              </Tooltip>
            </div>
          )}
        </>
      )}
    </StepBarFrame>
  );
};

const Option = (props) => {
  const { text } = props;
  if (!text) return <></>;
  return (
    <div
      style={{
        position: "absolute",
        bottom: -14,
        right: 0,
        fontSize: 10,
      }}
    >
      {text}
    </div>
  );
};

const StepOne = (props) => {
  const {
    number,
    label,
    currentStep,
    option = null,
    value = null,
    onClick,
  } = props;
  const valueAry = value ? value.split("\n") : null;
  const className =
    value !== null ? "done" : number === currentStep ? "active" : "inactive";
  const stepDoneImg = (
    <img src="/img/stepDone.svg" style={{ width: "33px", height: "33px" }} />
  );
  const numberContent =
    value !== null ? stepDoneImg : <StepNumber>{number}</StepNumber>;
  const content = value !== null ? valueAry : [label];
  return (
    <StepFrame className={className}>
      <StepLabelPart>
        {numberContent}
        <StepButton
          onClick={onClick}
          className={onClick ? "customer-button" : ""}
          style={{ width: 210 }}
        >
          {content.map((v, i) => (
            <div key={i}>{v}</div>
          ))}
          <Option text={option} />
        </StepButton>
      </StepLabelPart>
    </StepFrame>
  );
};

const Step = (props) => {
  const {
    number,
    label,
    currentStep,
    option = null,
    value = null,
    onClick,
  } = props;
  const valueAry = value ? value.split("\n") : null;
  const className =
    value !== null ? "done" : number === currentStep ? "active" : "inactive";
  const stepDoneImg = (
    <img src="/img/stepDone.svg" style={{ width: "33px", height: "33px" }} />
  );
  const numberContent =
    value !== null ? stepDoneImg : <StepNumber>{number}</StepNumber>;
  const content = value !== null ? valueAry : [label];
  return (
    <StepFrame className={className}>
      <StepLabelPart>
        {numberContent}
        <StepLabel onClick={onClick} className={onClick ? "clickable" : ""}>
          {content.map((v, i) => (
            <div key={i}>{v}</div>
          ))}
          <Option text={option} />
        </StepLabel>
      </StepLabelPart>
    </StepFrame>
  );
};

const StepFour = (props) => {
  const { currentStep, mode, reportChecked = false } = props;

  const pageEditorContext = useContext(PageEditorContext);
  const portfolioContext = useContext(PortfolioContext);
  const viewMode = pageEditorContext.viewMode || portfolioContext.viewMode;

  const location = useLocation();
  const path = viewMode
    ? "/workspace/:workspaceId/:someResource"
    : "/workspace/:workspaceId/:someResource/:crud";
  const match = matchPath(path, location.pathname);
  const workspaceId = match?.params?.workspaceId;
  const basePath = `/workspace/${workspaceId}`;
  const className = [
    "four-five",
    4 === currentStep ? "active" : "inactive",
  ].join(" ");
  const portClassName = ["port", mode === "port" ? "active" : "inactive"].join(
    " "
  );
  const reportClassName = reportChecked
    ? "disabled"
    : mode === "report"
    ? "active"
    : "inactive";
  const navigate = useNavigate();
  const openPort = () => {
    if (mode == "report") {
      navigate(`${basePath}/portfolio/${viewMode ? "" : "edit"}`);
    }
  };
  const openReport = () => {
    if (mode == "port") {
      navigate(`${basePath}/report/${viewMode ? "" : "edit"}`);
    }
  };
  return (
    <StepFourFrame className={className}>
      <StepLabelPart className="four">
        {viewMode ? <></> : <StepFourFiveNumber>4</StepFourFiveNumber>}
        <StepButton className={portClassName} onClick={openPort}>
          ポートフォリオ{viewMode ? "" : "設定"}
        </StepButton>
        <StepButton
          className={reportClassName}
          onClick={openReport}
          style={viewMode ? { marginRight: 41 } : {}}
        >
          レポート{viewMode ? "" : "作成"}
        </StepButton>
      </StepLabelPart>
    </StepFourFrame>
  );
};

const StepFive = (props) => {
  const { currentStep, reportChecked = false, onClick } = props;
  const pageEditorContext = useContext(PageEditorContext);
  const portfolioContext = useContext(PortfolioContext);
  const viewMode = pageEditorContext.viewMode || portfolioContext.viewMode;
  const className = [
    "four-five",
    4 === currentStep ? "active" : "inactive",
  ].join(" ");
  const submitClassName = reportChecked ? "disabled" : "inactive";
  return viewMode ? (
    <></>
  ) : (
    <StepFiveFrame className={className}>
      <StepLabelPart className="five">
        <StepFourFiveNumber>5</StepFourFiveNumber>
        <StepButton onClick={onClick} className={submitClassName}>
          レポート確定
        </StepButton>
      </StepLabelPart>
    </StepFiveFrame>
  );
};

const StepFrame = styled.div`
  min-width: 260px;
  max-width: 350px;
  flex-grow: 1;
  height: 57px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #1fd7ff;
  margin-right: 15px;
  &.inactive {
    border: unset;
  }
  &.done {
    border-bottom: 2px solid #d2f2ff;
  }
`;

const StepLabelPart = styled.div`
  min-width: 260px;
  max-width: 350px;
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &.four {
    width: auto;
    /* width: 359px; */
    /* margin-right: 10px; */
  }
  &.five {
    /* width: 200px; */
    min-width: 200px;
    justify-content: right;
    /* margin-right: 30px; */
  }
`;

const StepNumber = styled.div`
  ${NotosansjpBoldCuriousBlue20px}
  font-weight: 400;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background-color: #1fd7ff;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  .inactive & {
    width: 33px;
    height: 33px;
    background-color: #dddcdb;
  }
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const StepLabel = styled.div`
  ${NotosansjpNormalCloudBurst14px}
  position: relative;
  color: #1fd7ff;
  width: 210px;
  height: 36px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .inactive & {
    color: #dddcdb;
  }
  .done & {
    color: #2397ce;
  }
  &.clickable {
    cursor: pointer;
  }
`;

const StepFourFrame = styled.div`
  min-width: 380px;
  width: 450px;
  height: 57px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-bottom: 2px solid #1fd7ff;
  margin-right: 15px;
  &.inactive {
    border: unset;
  }
`;

const StepFiveFrame = styled.div`
  width: 200px;
  height: 57px;
  display: flex;
  flex-direction: column;
  /* align-items: left; */
  justify-content: right;
  border-bottom: 2px solid #1fd7ff;
  margin-right: 15px;
  &.inactive {
    border: unset;
  }
`;

const StepFourFiveNumber = styled.div`
  ${NotosansjpBoldCuriousBlue20px}
  font-weight: 400;
  width: 14px;
  height: 21px;
  font-size: 24px;
  color: #1fd7ff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  .inactive & {
    color: #ffffff;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    background-color: #dddcdb;
    margin-right: 8px;
  }

  .five & {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

const StepButton = styled.button`
  width: 145px;
  height: 40px;
  vertical-align: middle;
  text-align: center;
  border-radius: 20px;
  ${NotosansjpNormalCloudBurst14px}
  outline: unset;
  border: unset;
  cursor: pointer;
  &.port {
    margin-right: 20px;
  }
  &.active {
    color: #ffffff;
    background-color: #2397ce;
    width: 138px;
    height: 36px;
    margin-right: 3.5px;
    cursor: initial;
  }
  &.port.active {
    margin-right: 23.5px;
  }
  .four-five:not(.inactive) &.inactive,
  &.customer-button {
    color: #2397ce;
    background-color: #d2f2ff;
    box-shadow: 2px 2px 10px rgba(147, 163, 169, 0.3);
  }
  .four-five:not(.inactive) &:active.inactive,
  &.customer-button:active {
    box-shadow: 0px 0px 2px rgba(147, 163, 169, 0.9);
    width: 138px;
    height: 36px;
    margin-right: 3.5px;
    margin-left: 3.5px;
  }
  .four-five:not(.inactive) &:active.port.inactive {
    margin-right: 23.5px;
    margin-left: 3.5px;
  }

  &.disabled {
    color: #ffffff;
    background-color: #dddcdb;
  }

  .inactive & {
    border-radius: unset;
    background-color: transparent;
    color: #dddcdb;
    text-align: left;
    cursor: initial;
  }
`;
const CloneButton = styled.button`
  width: 40px;
  height: 40px;
  vertical-align: middle;
  text-align: center;
  border-radius: 20px;
  outline: unset;
  border: unset;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px rgba(147, 163, 169, 0.3);
  &:hover {
    background-color: #d2f2ff;
  }
  &:active {
    background-color: #192e55;
    box-shadow: unset;
  }
`;

const StepBarFrame = styled.div`
  min-width: 1435px;
  height: 97px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* justify-content: center; */
  justify-content: start;
  /* padding: 39px 60px 20px 60px; */
  padding: 0 60px 20px 60px;
  flex-shrink: 0;
`;
// const SubmitButton = styled.button`
//   align-self: center;
// `;

const Cover = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const pageEditorContext = useContext(PageEditorContext);
  const portfolioContext = useContext(PortfolioContext);
  const viewMode = pageEditorContext.viewMode || portfolioContext.viewMode;

  const path = viewMode
    ? "/workspace/:workspaceId/:someResource"
    : "/workspace/:workspaceId/:someResource/:crud";
  const match = matchPath(path, location.pathname);
  const workspaceId = match?.params?.workspaceId;
  const resource = match?.params?.someResource;
  const pathAfterFreeze = `/workspace/${workspaceId}/${resource}/`;

  const { show = false, onClick } = props;
  const className = show ? "show" : "hide";
  const [submitted, setSubmitted] = useState(false);
  const submitClassName = submitted ? "show" : "hide";
  const submitClickHandler = async (e) => {
    e.preventDefault();
    const result = await freezeWorkspace({ workspaceId });
    console.log("freezeWorkspace result", result);
    setSubmitted(true);
  };
  const closeClickHandler = (e) => {
    e.preventDefault();
    setSubmitted(false);
    navigate(pathAfterFreeze);
    onClick(e);
  };
  return (
    <>
      <CoverFrame
        className={[className, submitted ? "submit" : ""].join(" ")}
      />
      <DialogFrame className={className}>
        <DialogHeader>
          <CheckIcon />
          <DialogTitle>レポート確定 提出</DialogTitle>
        </DialogHeader>

        <DialogMessage>
          レポート作成を完了します。 <br />
          この操作直後から編集不可になりますがよろしいですか？
        </DialogMessage>
        <DialogButtons>
          <DialogButton onClick={submitClickHandler} className="accent">
            決定
          </DialogButton>
          <DialogButton onClick={onClick}>キャンセル</DialogButton>
        </DialogButtons>
      </DialogFrame>
      <DialogFrame className={submitClassName}>
        <DialogHeader>
          <CheckIconGradationion />
        </DialogHeader>

        <DialogMessage>レポート提出しました</DialogMessage>
        <DialogButtons>
          <DialogButton onClick={closeClickHandler} className="accent">
            戻る
          </DialogButton>
        </DialogButtons>
      </DialogFrame>
    </>
  );
};

const CoverFrame = styled.div`
  background: linear-gradient(150deg, #50abff 0%, #affafe 100%);
  opacity: 0.4;
  z-index: 9000000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &.hide {
    display: none;
  }
  &.submit {
    opacity: 1;
    animation: fadeout 2s linear;
  }
  @keyframes fadeout {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;
const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
const DialogTitle = styled.div`
  ${NotosansjpBoldCuriousBlue20px}
  margin-left: 16px;
  color: #192e55;
`;

const DialogMessage = styled.div`
  ${NotosansjpMediumCloudBurst12px}
  color: #192E55;
  margin-top: 8px;
  margin-left: 40px;
`;

const DialogFrame = styled.div`
  opacity: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  position: fixed;
  top: 40vh;
  left: 40vw;
  margin: 0 auto;
  width: 448px;
  height: 184px;
  z-index: 10000000;
  &.hide {
    display: none;
  }
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
`;

const DialogButtons = styled.div`
  margin: 8px 24px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;
const DialogButton = styled.button`
  ${NotosansjpMediumCloudBurst12px}
  width: 80px;
  height: 36px;
  margin-left: 20px;
  border: 1px solid #dddcdb;

  &.accent {
    border: unset;
    background-color: #2397ce;
    color: white;
  }
`;

const CheckIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(/img/CheckCircle.svg);
`;

const CheckIconGradationion = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(/img/CheckCircleWGradation.svg);
`;

const WSFrameDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
